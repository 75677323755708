import { PageProps } from "gatsby";
import * as React from "react";
import Seo from "src/components/Seo";
import Contests from "src/routes/Contests";

const ContestsPage: React.SFC<PageProps> = (props) => {
  return (
    <div>
      <Seo
        pageTitle="Конкурсы"
        pageDescription={
          "Онлайн-образование для сценаристов, режиссеров и продюсеров. Полноценные курсы от профессионалов киноиндустрии, с домашними заданиями и личным куратором."
        }
      />
      <Contests {...props} />
    </div>
  );
};

export default ContestsPage;
