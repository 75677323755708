import * as React from "react";

import { Contest as ContestType } from "src/types";
import classes from "./Contests.module.css";
import Filters from "./Filters";
import Scroll from "./Scroll";

interface P {
  data: ContestType[];
  pagination: {
    total: number;
    page: number;
    hasNextPage?: boolean;
  };
  handleChange(v: any, type: string): void;
  getNewData(): void;
}

const Contests: React.SFC<P> = (props) => {
  return (
    <div className={classes.wrapper}>
      <Filters handleChange={props.handleChange} />
      <Scroll
        data={props.data}
        pagination={props.pagination}
        getNewData={props.getNewData}
      />
    </div>
  );
};

export default Contests;
