import * as React from "react";
import Expand from "react-expand-animated";
import Api from "src/api";
import Contest from "src/components/ContestAnnounce";
import classes from "./Top.module.css";

class Top extends React.Component<any, any> {
  public state = {
    announces: this.props.announces || [],
  };

  public async componentDidMount() {
    const res = await Api.getTopContests();
    this.setState({
      announces: res.data,
    });
  }

  public render() {
    if (!this.state.announces) return null;
    return (
      <Expand open={!!this.state.announces?.length}>
        <div className={classes.wrapper}>
          {this.state.announces.map((curr) => (
            <Contest key={curr._id} {...curr} bigHeader />
          ))}
        </div>
      </Expand>
    );
  }
}

export default Top;
