import { PageProps } from "gatsby";
import * as React from "react";
import Api from "src/api";
import PageHeader from "src/components/PageHeader/PageHeader";
import { Contest as ContestType, PaginationParams } from "src/types";
import Contests from "./Contests";
import Top from "./Top";

type State = {
  announces: ContestType[];
  pagination: {
    total: number;
    page: number;
    hasNextPage?: boolean;
    limit: number;
  };
  filters: {
    free?: boolean;
    format?: string;
  };
};

interface Props extends PageProps {
  announces?: ContestType[];
}

class ContestsContainer extends React.Component<Props, State> {
  public state = {
    announces: this.props.announces || [],
    filters: {
      free: null,
      format: null,
    },
    pagination: {
      total: 0,
      page: 0,
      limit: 7,
      hasNextPage: true,
    },
  };

  public async componentDidMount() {
    await this.getData();
  }

  public handleFiltersChange = (value: any, type: string) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [type]: value,
      },
    });
  };

  public async componentDidUpdate(_, prevState: State) {
    if (
      prevState.filters.free !== this.state.filters.free ||
      prevState.filters.format !== this.state.filters.format
    ) {
      await this.getData({}, { clean: true });
    }
  }

  public render() {
    return (
      <div>
        <PageHeader text="Конкурсы" />
        <Top />
        <Contests
          data={this.state.announces}
          handleChange={this.handleFiltersChange}
          pagination={this.state.pagination}
          getNewData={this.getData}
        />
      </div>
    );
  }

  private getData = async (query = {}, options: { clean?: boolean } = {}) => {
    const res = await Api.getContestsAnnounces(
      this.state.filters,
      this.getReqData(query, options)
    );
    this.updateData(res.data, options);
  };

  private getReqData = (query = {}, options: { clean?: boolean }) => {
    if (options.clean) {
      return {
        page: 1,
        limit: this.state.pagination.limit,
        ...query,
      };
    }

    return {
      page: this.state.pagination.page ? this.state.pagination.page + 1 : 1,
      limit: this.state.pagination.limit,
      ...query,
    };
  };

  private updateData = (
    data: PaginationParams<ContestType>,
    options: { clean?: boolean } = {}
  ) => {
    if (options.clean) {
      this.setState({
        announces: data.data,
        pagination: {
          limit: this.state.pagination.limit,
          total: data.total || 0,
          page: data.page,
          hasNextPage: data.hasNextPage,
        },
      });
      return;
    }

    this.setState({
      announces: [...this.state.announces, ...data.data],
      pagination: {
        ...this.state.pagination,
        total: data.total || 0,
        page: data.page,
        hasNextPage: data.hasNextPage,
      },
    });
  };
}

export default ContestsContainer;
