import * as React from "react";
import Dropdown from "src/components/Dropdown";
import { CONTESTS_FORMATS_OPTIONS } from "src/consts";
import classes from "./Filters.module.css";

interface P {
  handleChange(v: any, type: string): void;
}

const Filters: React.SFC<P> = (props) => {
  return (
    <div className={classes.wrapper}>
      <Dropdown
        type="free"
        label="Цена"
        onChange={props.handleChange}
        options={[
          {
            value: "",
            label: "Все",
          },
          {
            value: "1",
            label: "Бесплатные",
          },
          {
            value: 0,
            label: "Платные",
          },
        ]}
      />
      <Dropdown
        type="format"
        label="Формат"
        onChange={props.handleChange}
        options={[
          {
            value: "",
            label: "Все",
          },
          ...CONTESTS_FORMATS_OPTIONS,
        ]}
      />
    </div>
  );
};

export default Filters;
