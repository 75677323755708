import * as React from "react";
import Expand from "react-expand-animated";
import allowSvg from "src/images/allow.svg";
import dropdownArrow from "src/images/dropdownArrow.svg";
import classes from "./Dropdown.module.css";

// tslint:disable jsx-no-lambda

export interface Props {
  defaultValue?: any;
  options: { value: any; label: string }[];
  type?: string;
  label?: string;
  withLine?: boolean;
  onChange(v: any, type?: string): void;
}

interface State {
  isOpen: boolean;
  value: string;
  label: string;
}

class Dropdown extends React.Component<Props, State> {
  public state = {
    isOpen: false,
    value: "",
    label: "",
  };

  public render() {
    const { props } = this;
    const { isOpen, label, value } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.select}>
          <div
            className={[
              classes.selectLabel,
              props.withLine && classes.withLine,
            ].join(" ")}
            onClick={this.toggleOpen}
            role="button"
          >
            {label || props.label}
            <img src={dropdownArrow} />
          </div>
          <Expand open={isOpen} className={classes.optionContainer}>
            {props.options.map((curr) => (
              <div
                role="button"
                key={curr.value}
                className={[
                  classes.option,
                  value === curr.value && classes.seleted,
                ].join(" ")}
                // tslint:disable-next-line: react-this-binding-issue
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  this.handleChange(curr.value, curr.label);
                }}
              >
                {curr.label}
                {value === curr.value && <img src={allowSvg} />}
              </div>
            ))}
          </Expand>
        </div>
      </div>
    );
  }

  private handleChange = (value, label) => {
    const { props } = this;
    props.onChange(value, props.type);
    this.setState({ value, isOpen: false, label });
  };

  private toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
}

export default Dropdown;
