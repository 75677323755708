import * as React from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import Contest from "src/components/ContestAnnounce";
import Typography from "src/components/Typography";
import { Contest as ContestType } from "src/types";
import classes from "./Contests.module.css";

interface Props {
  data: ContestType[];
  pagination: {
    total: number;
    page: number;
    hasNextPage?: boolean;
  };
  getNewData(): void;
}

const Scroll: React.SFC<Props> = (props) => {
  if (!props.data?.length) {
    return (
      <div
        style={{
          margin: "88px auto",
        }}
      >
        <Typography type="h3">Кажется, тут ничего нет</Typography>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={props.data.length} //This is important field to render the next data
      next={props.getNewData}
      hasMore={props.pagination.hasNextPage}
      loader={<Typography type="boldText">Загружаем</Typography>}
      className={classes.mappedAnnounces}
    >
      {props.data.map((curr) => (
        <Contest key={curr._id} {...curr} />
      ))}
    </InfiniteScroll>
  );
};

export default Scroll;
